import React, { MouseEvent, useEffect } from 'react';
import styled from 'styled-components';
import media, { defaultBreakpoints } from 'styled-media-query';
import backArrowImg from './images/navigation/back-arrow.svg';
import { RootState } from './types/States';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from './actions';
import SoundCloudLogin from './SoundCloudLogin';
import PaymentConfirmation from './PaymentConfirmation';
import PlanSelection from './PlanSelection';
import ProgressStepIndicator from './components/ProgressStepIndicator';
import Button from './Button';
import { useMediaQuery } from 'react-responsive';
import { logEvent } from 'firebase/analytics';
import { analytics } from './common/firebase';
import Analytics from "./Analytics";

const StyledContainer = styled.div`
  background-color: #faf6e8;
  padding: 3em 1em;
`;

const StyledContent = styled.div`
  margin: auto;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledSubNavBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StyledNavigationIconContainer = styled.div`
    width: 0;
`;

const StyledBackArrowImg = styled.img`
    cursor: pointer;
    opacity: 0.7;
`;

const StyledCTAContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0.8em;
    ${media.greaterThan("medium")`
        margin-top: 1.3em;
    `}
`;

const StyledPrimaryButton = styled(Button)`
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  white-space: nowrap;
  font-size: 16px;
  ${media.greaterThan("medium")`
    font-size: 1.0em;
    width: unset;
  `}
`;

const Onboarding: React.FC = (props: any ) => {

    const isDesktop = useMediaQuery({
        minWidth: defaultBreakpoints.medium
    });

    useEffect(() => {
        if (props.currentUser.onboardingStep === 1 && isDesktop) {
            window.scrollTo(0, 140)
        } else {
            window.scrollTo(0, 0)
        }
    }, [props.currentUser.onboardingStep, isDesktop]);

    const { currentUser } = props
    if (!currentUser) {
        return null
    }

    const handlePreviousButtonClick = (evt: MouseEvent<HTMLImageElement>) => {
        evt.preventDefault();
        switch(currentUser.onboardingStep as 1 | 2 | 3) {
            case 2: {
                logEvent(analytics, 'ff_button_click', {
                    name: `sc_login_back`
                });
                break;
            }
            case 3: {
                logEvent(analytics, 'ff_button_click', {
                    name: `payment_confirmation_back`
                });
                break;
            }
        }
        props.previousOnboardingStep(currentUser);
    };

    const handleNextButtonClick = (evt: MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        switch(currentUser.onboardingStep as 1 | 2 | 3) {
            case 1: {
                logEvent(analytics, 'ff_button_click', {
                    name: `plan_selected_next`
                });
                break;
            }
            case 2: {
                logEvent(analytics, 'ff_button_click', {
                    name: `sc_login_next`
                });
                break;
            }
        }
        props.nextOnboardingStep(currentUser);
    };

    const renderOnboardingContent = () => {
        switch(currentUser.onboardingStep as 1 | 2 | 3) {
            case 1:
                return (
                    <PlanSelection showYearlyPlans={false}/>
                );
            case 2:
                return (
                    <SoundCloudLogin/>
                );
            case 3:
                if (currentUser.selectedPlan === 'INTRO') {
                    return (
                      <Analytics/>
                    )
                }
                return (
                    <PaymentConfirmation/>
                );
        }
    };

    const isNextButtonEnabled = () => {
        switch (currentUser.onboardingStep) {
            case 1: {
                return currentUser.selectedPlan !== null
            }
            case 2: {
                return currentUser.isSCAuthed;
            }
            case 3: {
                return true;
            }
        }
    };

    const showBackButton = () => {
        switch(currentUser.onboardingStep) {
            case 1: {
                return false
            }
            case 2: {
                return !currentUser.subscription

            }
            case 3: {
                return currentUser.selectedPlan !== 'INTRO'
            }
        }
    }


    return (
        <StyledContainer>
            <StyledContent>
                <StyledDiv>
                    {showBackButton() &&
                        <StyledSubNavBar>
                            <StyledNavigationIconContainer>
                                { [2,3].includes(currentUser.onboardingStep) &&
                                    <StyledBackArrowImg onClick={handlePreviousButtonClick} src={backArrowImg} alt=''/>
                                }
                            </StyledNavigationIconContainer>
                            <ProgressStepIndicator numSteps={3} currentStep={currentUser.onboardingStep as 1 | 2 | 3}/>
                        </StyledSubNavBar>
                    }
                    {renderOnboardingContent()}
                    <StyledCTAContainer>
                        {
                            (props.currentUser.onboardingStep === 1 || (currentUser.onboardingStep === 2 && currentUser.isSCAuthed)) &&
                            <StyledPrimaryButton disabled={!isNextButtonEnabled()}
                                                 onClick={handleNextButtonClick}>next</StyledPrimaryButton>
                        }
                    </StyledCTAContainer>
                </StyledDiv>
            </StyledContent>
        </StyledContainer>
    );
};

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.loading.isLoading,
        currentUser: state.auth.currentUser,
    }
}

export default compose(
    connect(mapStateToProps, actions)
)(Onboarding);
